import { get, post } from "@/service/index.js";

// 开票订单-分页
export const pageInvoicingTrade = (param) => {
  return get("/sal/invoicing/trade/page", param).then((res) => res);
};
// 开票订单-作废
export const revokeInvoicingTrade = (param) => {
  return post("/sal/invoicing/trade/revoke", param).then((res) => res);
};
// 开票订单-汇总
export const summaryInvoicingTrade = (param) => {
  return get("/sal/invoicing/trade/summary", param).then((res) => res);
};
export const getOrgByUser = () => {
  return get("/organization/getOrgByUserId").then((res) => res);
};