<template>
  <div class="order_manage_view content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form
          ref="selectFrom"
          :model="queryParams"
          class="select-form"
          :inline="true"
          label-width="100px"
        >
          <el-form-item label="销方名称" for="sellerOrgId">
            <organization-select
              :is-add-all="false"
              all-label="请选择销方主体"
              clearable
              filterable
              :model="queryParams"
            ></organization-select>
          </el-form-item>

          <el-form-item label="订单编号" for="sheetCode">
            <el-input
              v-model.trim="queryParams.sheetCode"
              placeholder="请输入订单编号"
              @keyup.enter.native="handleQuery"
              clearable
            />
          </el-form-item>

          <el-form-item label="业务单号" for="businessCode">
            <el-input
              v-model.trim="queryParams.businessCode"
              placeholder="请输入业务单号"
              @keyup.enter.native="handleQuery"
              clearable
            />
          </el-form-item>

          <el-form-item label="购方名称" for="buyerName">
            <el-input
              v-model.trim="queryParams.buyerName"
              placeholder="请输入购方名称"
              @keyup.enter.native="handleQuery"
              clearable
            />
          </el-form-item>

          <el-form-item
            label="订单金额"
            for="sheetSumAmount"
            v-show="selectExpended"
          >
            <el-input
              type="number"
              v-model.trim="queryParams.sheetSumAmount"
              placeholder="请输入订单金额"
              @keyup.enter.native="handleQuery"
              clearable
            />
          </el-form-item>

          <el-form-item
            label="订单状态"
            for="sheetStatus"
            v-show="selectExpended"
          >
            <el-select
              v-model="queryParams.sheetStatus"
              placeholder="请选择订单状态"
              clearable
              :loading="dictionaryLoading"
              @keyup.enter.native="handleQuery"
            >
              <el-option
                v-for="item in dictionary.tradeSheetStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="订单时间"
            for="tradeTime"
            v-show="selectExpended"
          >
            <el-date-picker
              v-model="queryParams.tradeTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              @keyup.enter.native="handleQuery"
              size="mini"
              style="width: 200px"
              clearable
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label="过期时间"
            for="expiredTime"
            v-show="selectExpended"
          >
            <el-date-picker
              v-model="queryParams.expiredTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd hh:mm:ss"
              :picker-options="pickerOptions"
              @keyup.enter.native="handleQuery"
              size="mini"
              style="width: 200px"
              clearable
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label="开票类型"
            for="billingType"
            v-show="selectExpended"
          >
            <el-select
              v-model="queryParams.billingType"
              placeholder="请选择开票类型"
              @keyup.enter.native="handleQuery"
              clearable
            >
              <el-option
                v-for="item in dictionary.invoiceBillingType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="创建时间"
            for="createdTime"
            v-show="selectExpended"
          >
            <el-date-picker
              v-model="queryParams.createdTime"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd hh:mm:ss"
              :picker-options="pickerOptions"
              @keyup.enter.native="handleQuery"
              size="mini"
              style="width: 200px"
              clearable
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item style="margin-left: 32px">
            <select-button
              show-status
              @list-close="selectExpended = false"
              @list-show="selectExpended = true"
              @select="handleQuery"
              @reset="handleReset"
            ></select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <div class="top-left">
            <el-popover placement="right" width="80" trigger="click">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选
              </el-checkbox>
              <div class="part"></div>
              <el-checkbox
                v-for="(pv, pk) in columns"
                :label="pv.label"
                :key="pk"
                v-model="pv.visible"
                style="display: block; margin-top: 6px"
                @change="handleCheckedCitiesChange(pk, pv)"
              />
              <div slot="reference" class="rowSet">
                <img class="imgSet" src="@/assets/icon/set.png" />
                <span>列设置</span>
              </div>
            </el-popover>
          </div>
        </div>
        <div class="content-table">
          <el-table
            ref="invoiceCenterTable"
            :data="dataList"
            stripe
            border
            v-loading="dataLoading"
            :header-cell-style="handleHeaderCellStyle"
          >
            <el-table-column
              type="index"
              width="80"
              label="序号"
              fixed="left"
              align="center"
            />
            <el-table-column
              v-if="columns['sheetCode'].visible"
              prop="sheetCode"
              :label="columns['sheetCode'].label"
              min-width="200"
              fixed="left"
              show-overflow-tooltip
            />
            <el-table-column
              v-if="columns['businessCode'].visible"
              prop="businessCode"
              fixed="left"
              :label="columns['businessCode'].label"
              min-width="160"
            />
            <el-table-column
              v-if="columns['sellerName'].visible"
              prop="sellerName"
              :label="columns['sellerName'].label"
              min-width="200"
              show-overflow-tooltip
            />
            <el-table-column
              v-if="columns['sellerCode'].visible"
              prop="sellerCode"
              :label="columns['sellerCode'].label"
              min-width="160"
            />
            <el-table-column
              v-if="columns['tradeTime'].visible"
              prop="tradeTime"
              :label="columns['tradeTime'].label"
              width="180"
            />
            <el-table-column
              v-if="columns['invoicedSumAmount'].visible"
              prop="invoicedSumAmount"
              :label="columns['invoicedSumAmount'].label"
              width="140"
              header-align="right"
              align="right"
              :formatter="fmtDataAmount"
            />
            <el-table-column
              v-if="columns['sheetSumAmount'].visible"
              prop="sheetSumAmount"
              :label="columns['sheetSumAmount'].label"
              width="180"
              header-align="right"
              align="right"
              :formatter="fmtDataAmount"
            />
            <el-table-column
              v-if="columns['buyerName'].visible"
              prop="buyerName"
              :label="columns['buyerName'].label"
              min-width="200"
              show-overflow-tooltip
            />
            <el-table-column
              v-if="columns['expiredTime'].visible"
              prop="expiredTime"
              :label="columns['expiredTime'].label"
              width="180"
            />
            <el-table-column
              v-if="columns['billingType'].visible"
              prop="billingType"
              :label="columns['billingType'].label"
              min-width="160"
              :formatter="fmtBillingType"
            />
            <el-table-column
              v-if="columns['invoicedTimes'].visible"
              prop="invoicedTimes"
              :label="columns['invoicedTimes'].label"
              header-align="right"
              align="right"
              width="100"
            />
            <el-table-column
              v-if="columns['created'].visible"
              prop="created"
              :label="columns['created'].label"
              width="180"
            />
            <el-table-column
              v-if="columns['updated'].visible"
              prop="updated"
              :label="columns['updated'].label"
              width="180"
            />
            <el-table-column
              v-if="columns['sheetStatus'].visible"
              prop="sheetStatus"
              :label="columns['sheetStatus'].label"
              width="100"
              fixed="right"
              :formatter="fmtSheetStatus"
            />
            <el-table-column
              v-if="columns['operate'].visible"
              prop="operate"
              :label="columns['operate'].label"
              fixed="right"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleItemRevoke(scope.row)"
                  :disabled="scope.row.sheetStatus == 'DDZF'"
                  >作废</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="table_total_box" v-loading="aggrLoading">
            <label>合计&nbsp;&nbsp;</label>
            <span>订单总数量：&nbsp;{{ dataAggr.total }}&nbsp;条</span>
            <span
              >订单总金额：&nbsp;{{
                fmtDataAmount(dataAggr.sheetSumAmount)
              }}&nbsp;元</span
            >
            <span
              >已开总次数：&nbsp;{{ dataAggr.invoicedTimes || 0 }}&nbsp;次</span
            >
            <span
              >已开票总额：&nbsp;{{
                fmtDataAmount(dataAggr.invoicedSumAmount)
              }}&nbsp;元</span
            >
          </div>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          :current-page.sync="pageParams.pageNo"
          @current-change="handleCurrentChange"
          :page-sizes="[20, 30, 50, 100]"
          :page-size.sync="pageParams.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="dataAggr.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import {
  pageInvoicingTrade,
  revokeInvoicingTrade,
  summaryInvoicingTrade,
} from "@/service/sal/invoicing-trade.js";
import { fmtMoney, fmtCurrency } from "@/assets/js/format-util";
import {
  tableStyle,
  pagingMethods,
  dateOption,
  changeMethods,
} from "@/util/mixins";

export default {
  name: "SalInvoicingTradeList",
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  components: { OrganizationSelect },
  data() {
    return {
      selectExpended: false,
      columns: {
        sellerName: {
          label: "销方名称",
          visible: true,
        },
        sellerCode: {
          label: "门店编码",
          visible: true,
        },
        sheetCode: {
          label: "订单编号",
          visible: true,
        },
        businessCode: {
          label: "业务单号",
          visible: true,
        },
        tradeTime: {
          label: "订单时间",
          visible: true,
        },
        sheetStatus: {
          label: "订单状态",
          visible: true,
        },
        invoicedSumAmount: {
          label: "已开票额（元）",
          visible: true,
        },
        sheetSumAmount: {
          label: "订单金额（元）",
          visible: true,
        },
        buyerName: {
          label: "购方名称",
          visible: true,
        },
        expiredTime: {
          label: "过期时间",
          visible: true,
        },
        billingType: {
          label: "开票类型",
          visible: true,
        },
        invoicedTimes: {
          label: "已开次数",
          visible: true,
        },
        created: {
          label: "创建时间",
          visible: true,
        },
        updated: {
          label: "更新时间",
          visible: true,
        },
        operate: {
          label: "操作",
          visible: true,
        },
      },
      queryParams: {
        orgId: JSON.parse(localStorage.getItem("orgId" || "")),
        sellerName: undefined,
        sheetCode: undefined,
        businessCode: undefined,
        sheetStatus: undefined,
        buyerName: undefined,
        billingType: undefined,
        sheetSumAmount: undefined,
        tradeTime: [],
        createdTime: [],
        expiredTime: [],
      },
      pageParams: {
        pageNo: 1,
        pageSize: 20,
      },

      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now();
        },
      },
      pickerDisabled: {
        disabledDate(date) {
          return date.getTime() > Date.now();
        },
      },

      dictionary: {
        tradeSheetStatus: [],
        invoiceBillingType: [],
      },
      dictionaryLoading: false,

      isIndeterminate: true,
      checkAll: false,

      dataList: [],
      dataLoading: false,

      dataAggr: {
        total: 0,
        sheetSumAmount: 0,
        invoicedTimes: 0,
        invoicedSumAmount: 0,
      },
      aggrLoading: false,
    };
  },
  async created() {
    this.queryParams.orgId = JSON.parse(localStorage.getItem("orgId" || ""));
    await this.initDict();
    this.handleQuery();
  },
  methods: {
    // 初始字典数据
    async initDict() {
      this.dictionaryLoading = true;
      this.dictionary.invoiceBillingType =
        this.$store.state.enums["BillingType"] || [];
      const { success, data } = await this.batchGetDicts({
        types: ["trade_sheet_status"],
      });
      if (success) {
        this.dictionary.tradeSheetStatus = data["trade_sheet_status"];
      }
      this.dictionaryLoading = false;
    },
    // 作废开票订单
    handleItemRevoke(row) {
      this.$confirm(`您确认要作废该订单（${row.sheetCode}）吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const { success } = await revokeInvoicingTrade({ id: row.id });
        if (success) {
          this.toast("作废成功", "success");
          this.handleQuery();
        }
      });
    },
    // 列头设置全选
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      let cols = this.columns;
      for (let p in cols) {
        cols[p].visible = val;
      }
      localStorage.setItem("otherInvoiceLineSet", JSON.stringify(this.columns));
    },
    // 列头设置改变
    handleCheckedCitiesChange(pk, pv) {
      Object.keys(this.columns).some((key) => {
        if (pk == key) {
          this.columns[key].visible = pv.visible;
          return true;
        }
      });
      localStorage.setItem("otherInvoiceLineSet", JSON.stringify(this.columns));
    },
    // 订单列表查询
    handleQuery() {
      this.queryParams.pageNo = 1;
      this.pagingInvoicingList();
      this.summaryInvoicingList();
    },
    // 分页查询订单
    async pagingInvoicingList() {
      this.dataLoading = true;
      if (this.queryParams.orgId > 0) {
        this.queryParams.sellerOrgId = this.queryParams.orgId;
      }
      let pageArg = Object.assign({}, this.queryParams, this.pageParams);
      const { success, data } = await pageInvoicingTrade(pageArg);
      if (success) {
        this.dataList = data.list;
      }
      this.dataLoading = false;
    },
    // 重置查询条件
    handleReset() {
      this.queryParams = {
        orgId: undefined,
        sellerName: undefined,
        sheetCode: undefined,
        businessCode: undefined,
        sheetStatus: undefined,
        buyerName: undefined,
        billingType: undefined,
        sheetSumAmount: undefined,
        tradeTime: [],
        createdTime: [],
        expiredTime: [],
        pageNo: 1,
        pageSize: 20,
      };
    },
    // 查询订单汇总
    async summaryInvoicingList() {
      this.aggrLoading = true;
      let aggrParam = JSON.parse(JSON.stringify(this.queryParams));
      Object.assign({ pageSize: undefined, pageNo: undefined }, aggrParam);
      const { success, data } = await summaryInvoicingTrade(this.aggrParam);
      if (success) {
        this.dataAggr = data[0] || {};
      }
      this.aggrLoading = false;
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.queryParams.pageNo = 1;
      this.queryParams.pageSize = val;
      this.pagingInvoicingList();
    },
    // 订单列表翻页
    handleCurrentChange(val) {
      this.queryParams.pageNo = val;
      this.pagingInvoicingList();
    },
    // 格式订单状态
    fmtSheetStatus(row, column, value) {
      return this.getDictLabel(this.dictionary.tradeSheetStatus, value);
    },
    // 格式开票类型
    fmtBillingType(row, column, value) {
      return this.handleValueToLabel("BillingType", value);
    },
    // 格式金额数据
    fmtDataAmount(row, column, value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
    fmtAggrAmount(value) {
      return fmtCurrency(value, 2, "", ",", ".", "");
    },
  },
};
</script>
<style lang="scss" scoped>
.order_manage_view {
  .table_total_box {
    display: flex;
    padding: 16px 10px;
    background: #ebf4ff;
    span {
      padding-right: 40px;
    }
  }
}

@import "@/style/select.scss";

.select-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  padding: 16px 24px;

  .select-items {
    display: inline-flex;

    div {
      color: #3d94ff;
      margin-right: 20px;
      font-weight: 500;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      line-height: 22px;

      img {
        width: 20px;
        height: 20px;
        vertical-align: sub;
        margin-right: 4px;
      }
    }

    div:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .select-title span:before {
    content: "|";
    display: inline-block;
    font-size: 16px;
    margin-right: 10px;
    background-color: #333;
  }
}

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .el-button {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1890ff;
        line-height: 22px;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 2px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }

  .content-table {
    .el-button {
      padding: 0;
      margin-right: 10px;
    }
  }
}

.manual-input {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;

    ::v-deep .el-select > .el-input,
    .el-input {
      width: 250px;
    }
  }
}

.phone-scan {
  .phone-scan-text {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 12px;
    }
  }

  .phone-scan-img {
    .el-image {
      width: 260px;
      height: 260px;
    }
  }
}

.invoice-update {
  .el-row {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}

.el-image-viewer {
  position: absolute;
  z-index: 1000;
}

.dialog-title {
  display: flex;
  justify-content: space-between;
  margin-top: -24px;
  margin-bottom: 24px;

  .title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
  }
}

.dialog-delete {
  display: flex;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-invoice-list {
  .list-statistics {
    text-align: left;

    .el-row {
      margin-bottom: 12px;
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .list-table {
    margin-top: 12px;

    div {
      text-align: left;
      margin-bottom: 12px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .el-button {
      padding: 0;
    }
  }
}

.dialog-settlement {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  text-align: left;
}

::v-deep .el-dialog__header {
  padding: 24px 24px 0px 24px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
}

::v-deep .el-dialog__body {
  padding: 24px 20px;
  color: #333333;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .el-form-item__error {
  padding-top: 0;
}

::v-deep .el-dialog__footer {
  padding: 0px 20px 20px;
  text-align: right;
  box-sizing: border-box;
}

.invoice-deleted {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 200px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.invoice-deleted-hidden {
  position: absolute;
  bottom: 0;
  margin-left: -24px;
  height: 48px;
  z-index: 9;
  width: calc(100% - 64px);
  line-height: 48px;
  box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.1);
}

.dialog-wrap {
  span {
    color: #333333;
  }

  .deletes-btns {
    padding-top: 24px;
    text-align: right;
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.icon {
  color: #ff0000;
  margin-left: 3px;
}

.button-footer {
  text-align: center;

  .button-left {
    margin-right: 65px;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.train21No {
  margin-right: 31px;
  margin-left: -31px;
  .el-input {
    width: 150px;
  }
}

.select-content {
  ::v-deep .select-form {
    width: auto;
    display: block;
  }

  ::v-deep .select-form > div {
    display: inline-flex;
  }
}

::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
</style>
